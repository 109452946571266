<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Transcription.Title") }}</h1>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>Configuración</h2>
          <div class="form-group row container">
            <label for="server" class="col-2 col-form-label">{{
              $t("Copla.Transcription.Language")
            }}</label>
            <div class="col-10">
              <select v-model="language" class="custom-select">
                <option
                  v-bind:value="id"
                  v-for="(value, id) in languages"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <div class="mt-4 col-3">
              {{ $t("Copla.Transcription.Profanity") }}
            </div>
            <div class="mt-4 col-9">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="profanity"
                  id="profanity0"
                  value="0"
                  checked
                  v-model="profanity"
                />
                <label class="form-check-label" for="profanity0">
                  {{ $t("Copla.Transcription.Profanities.Masked") }}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="profanity"
                  id="profanity1"
                  value="1"
                  v-model="profanity"
                />
                <label class="form-check-label" for="profanity1">
                  {{ $t("Copla.Transcription.Profanities.Removed") }}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="profanity"
                  id="profanity2"
                  value="2"
                  v-model="profanity"
                />
                <label class="form-check-label" for="profanity2">
                  {{ $t("Copla.Transcription.Profanities.Raw") }}
                </label>
              </div>
            </div>
          </div>
          <div class="form-group row mt-4">
            <label for="lines" class="col-2 col-form-label text-center">{{
              $t("Copla.MercurioSubtitles.Format.Lines")
            }}</label>
            <div class="col-10">
              <input
                type="number"
                class="form-control"
                name="lines"
                id="lines"
                min="1"
                v-model="lineas"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="characters" class="col-2 col-form-label text-center">{{
              $t("Copla.MercurioSubtitles.Format.Characters")
            }}</label>
            <div class="col-10">
              <input
                type="number"
                class="form-control"
                name="characters"
                id="characters"
                min="1"
                max="120"
                v-model="caracteres"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="grammar" class="col-2 col-form-label text-center">{{
              $t("Copla.Transcription.Vocabulary")
            }}</label>
            <div class="col-9">
              <input
                type="text"
                class="form-control"
                name="grammar"
                id="grammar"
                v-model="word"
              />
            </div>
            <div class="col-1">
              <button
                type="button"
                id="add"
                name="add"
                class="btn"
                :class="transcribing ? 'btn-danger' : 'btn-success'"
                v-on:click="addGrammar"
              >
                {{ $t("Copla.Transcription.Button-Add") }}
              </button>
            </div>
            <div class="col mt-3" v-if="grammarList.length > 0">
              <ul class="list-inline">
                <li
                  class="list-inline-item vocabulario"
                  v-bind:value="id"
                  v-for="(value, id) in grammarList"
                  :key="id"
                >
                  {{ value }}
                  <span class="deleteGrammar" v-on:click="deleteGrammar(id)"
                    >&#10008;</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="border rounded secciones mt-4">
          <div class="form-group row">
            <label for="file" class="col-3 col-form-label text-center">{{
              $t("Copla.Transcription.File")
            }}</label>
            <div class="col-9">
              <input
                type="file"
                class="form-control"
                id="file"
                name="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept=".txt"
                required
              />
            </div>
          </div>
        </div>
        <div class="border rounded secciones mt-4">
          <span v-html="mensajes" class="mensajes" v-if="mensajes != ''"></span>
        </div>
        <div class="border rounded secciones mt-4" id="transcription-box">
          <span v-html="transcription"></span>
        </div>
        <div class="row mt-3">
          <div class=" col mr-3">
            <div class="text-center">
              <button
                type="button"
                id="comenzar"
                name="comenzar"
                class="btn"
                :class="transcribing ? 'btn-danger' : 'btn-success'"
                v-on:click="connect"
              >
                {{ btnText }}
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="!transcribing && transcription != ''">
          <div class=" col text-center">
            <button
              type="button"
              id="descarga"
              name="descarga"
              class="btn btn-primary mr-3"
              v-on:click="downloadTranscription"
            >
              {{ $t("Copla.Transcription.Download") }}
            </button>
            <button
              type="button"
              id="descargaSRT"
              name="descargaSRT"
              class="btn btn-primary mr-3"
              v-on:click="downloadSRT"
            >
              {{ $t("Copla.Transcription.DownloadSRT") }}
            </button>
            <button
              type="button"
              id="sincro"
              name="sincro"
              class="btn btn-primary"
              v-on:click="sincro"
            >
              {{ $t("Copla.Transcription.Sincro") }}
            </button>
          </div>
          <!--<div class="text-center mr-3">
              <button
                type="button"
                id="descargaBBVA"
                name="descargaBBVA"
                class="btn btn-info"
                v-on:click="downloadBBVA"
              >
                {{ $t("Copla.Transcription.DownloadBBVA") }}
              </button>
            </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MainMenu from "../components/MainMenu";
import formatSubtitlesAPI from "../js/formatSubtitlesAPI";
export default {
  name: "Transcription",
  components: {
    MainMenu
  },
  data() {
    return {
      word: "",
      grammarList: [],
      transcription: "",
      transcribing: false,
      recognizer: undefined,
      btnText: this.$t("Copla.Transcription.Button-On"),
      languages: this.$t("Copla.Transcription.Languages"),
      language: "es-ES",
      timedText: {},
      profanity: 2,
      lineas: 2,
      caracteres: 40,
      mensajes: "",
      fileDownload: "transcription.txt",
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      file: "",
      lastOffset: 0,
      initOffset: 0,
      init: 0,
      id: -1
    };
  },
  mounted() {},
  watch: {
    transcribing: function(val) {
      this.btnText = val
        ? this.$t("Copla.Transcription.Button-Off")
        : this.$t("Copla.Transcription.Button-On");
    }
  },
  methods: {
    addGrammar: function() {
      if (this.word != "") {
        this.grammarList.push(this.word);
        this.word = "";
      }
    },
    deleteGrammar: function(id) {
      this.grammarList.splice(id, 1);
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
    download: function(content) {
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(content)
      );
      element.setAttribute("download", this.fileDownload);
      element.style.display = "none";
      element.click();
    },
    downloadTranscription: function() {
      this.fileDownload = "transcription.txt";
      this.download(this.transcription);
    },
    sincro: function() {
      let self = this;
      if (self.file != "") {
        let json = [];
        for (var i = 0; i < this.timedText.words.length; i++) {
          if (this.timedText.words[i] !== "undefined") {
            for (var j = 0; j < this.timedText.words[i].length; j++) {
              json.push(this.timedText.words[i][j]);
            }
          }
        }
        let formData = new FormData();
        formData.append("_file", this.file);
        formData.append("_json", JSON.stringify(json));
        this.mensajes = "Sincronizando...";
        let serverURL = "https://formatsubtitles." + this.base_url + "/";
        axios
          .post(serverURL + "api/v1/sincro", formData, {
            headers: { "Content-Type": "multipart/form-data" }
          })
          .then(function(response) {
            if (response.status == 200) {
              self.mensajes =
                "Download file: <a href='" +
                serverURL +
                response.data.data +
                "'>SRT file</a>";
            } else {
              self.mensajes = response.status;
            }
          })
          .catch(function(response) {
            self.mensajes = response;
          });
      } else {
        self.mensajes =
          "Introduzca el fichero de la transcripción a sincronizar.";
      }
    },
    downloadSRT: function() {
      let self = this;
      let params = {
        _transcription: self.transcription,
        _phrases: self.timedText.text
      };
      self.mensajes = this.$t("Copla.Transcription.Creating");
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.getTimedTextSRT(params, self.id).then(response => {
        if (response.code == "200") {
          self.fileDownload = "subtitles.srt";
          self.download(response.data);
        } else {
          self.messages = response.data;
        }
      });
      /*let serverURL = "https://formatsubtitles." + this.base_url + "/";
      axios
        .put(serverURL + "api/v2/timedText", params, {
          headers: { "Content-Type": "text/plain" }
        })
        .then(function(response) {
          if (response.status == 200) {
            console.log(response);
            self.mensajes =
              "Download file: <a href='" +
              serverURL +
              response.data.data +
              "'>SRT File</a>";
          } else {
            self.mensajes = "Error";
          }
        })
        .catch(error => console.log(error));*/
    },
    connect: function() {
      let self = this;
      var SpeechSDK = window.SpeechSDK;
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        "9d2d6a064406477ca1ef992c4a20872d",
        "westeurope"
      );
      speechConfig.speechRecognitionLanguage = this.language;
      speechConfig.outputFormat = 1;
      speechConfig.setProfanity(self.profanity);
      speechConfig.requestWordLevelTimestamps();
      const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      if (!this.transcribing) {
        let anterior = "";
        self.mensajes = "Transcribing... ";
        this.timedText = {
          text: [],
          lines: self.lineas,
          characters: self.caracteres
        };
        self.recognizer = new SpeechSDK.SpeechRecognizer(
          speechConfig,
          audioConfig
        );
        const phraseList = SpeechSDK.PhraseListGrammar.fromRecognizer(
          self.recognizer
        );
        self.grammarList.forEach(function(value) {
          phraseList.addPhrase(value);
        });
        self.recognizer.startContinuousRecognitionAsync(
          function() {
            self.transcribing = true;
            self.transcription = "";
            self.init = Date.now();
          },
          function(err) {
            self.transcription = err;
            self.recognizer.dispose(true);
            self.recognizer.close();
            self.recognizer = undefined;
          }
        );
        self.recognizer.recognizing = function(s, e) {
          self.transcription =
            anterior +
            e.result.text.charAt(0).toUpperCase() +
            e.result.text.slice(1);
          this.$el.getElementsById("transcription-box")[0].scrollIntoView();
          var palabra = [];
          palabra.push(Date.now());
          palabra.push(e.result.text);
          self.timedText.push(palabra);
        };
        self.recognizer.recognized = function(s, e) {
          console.log(e.result);
          let result = JSON.parse(e.result.json);
          if (result.RecognitionStatus == "Success") {
            let newEntry = { phrase: {}, words: [] };
            newEntry.phrase.text = result.DisplayText;
            let horaActual = Date.now();
            let offsetInit =
              (horaActual - result.Duration / 10000 - self.init) * 10000 -
              5000000;
            let wordsRecognized = result.NBest[0].Words;
            let offsetFirstWord = wordsRecognized[0].Offset;
            if (self.initOffset == 0) {
              self.initOffset = wordsRecognized[0].Offset;
            }
            if (wordsRecognized[0].Offset < self.lastOffset) {
              wordsRecognized.forEach(function(value, index) {
                wordsRecognized[index].Offset =
                  offsetInit + (value.Offset - offsetFirstWord);
              });
            }
            //self.timedText.words.push(result.NBest[0].Words);
            newEntry.words = wordsRecognized;
            newEntry.phrase.offset = wordsRecognized[0].Offset;
            newEntry.phrase.duration = result.Duration;
            self.lastOffset =
              wordsRecognized[wordsRecognized.length - 1].Offset;
            self.timedText.text.push(newEntry);
          }
          let newPhrase =
            e.result.text.charAt(0).toUpperCase() + e.result.text.slice(1);
          anterior += newPhrase + " ";
          self.transcription = anterior;
          this.$el.getElementsById("transcription-box")[0].scrollIntoView();
        };
        self.recognizer.canceled = function() {
          self.transcribing = false;
          self.recognizer.dispose(true);
          self.recognizer.close();
          self.recognizer = undefined;
        };
        self.recognizer.sessionStopped = function() {
          self.transcribing = false;
          self.recognizer.dispose(true);
          self.recognizer.close();
          self.recognizer = undefined;
        };
      } else {
        self.mensajes = "";
        self.recognizer.stopContinuousRecognitionAsync(
          function() {
            self.transcribing = false;
            self.recognizer.dispose(true);
            self.recognizer.close();
            self.recognizer = undefined;
          },
          function() {
            self.transcribing = false;
            self.recognizer.dispose(true);
            self.recognizer.close();
            self.recognizer = undefined;
          }
        );
      }
    },
    findFirstDiffPos: function(a, b) {
      var i = 0;
      if (a === b) return -1;
      while (a[i] === b[i]) i++;
      return i;
    },
    findLocations: function(substring, string, index) {
      var a = index,
        i = -1;
      while ((i = string.indexOf(substring, i + 2)) >= 0) {
        if (i < index) a = i;
        else break;
      }
      return a;
    }
  }
};
</script>

<style scoped>
.deleteGrammar {
  color: red;
}
.deleteGrammar:hover {
  cursor: pointer;
}
.vocabulario {
  border: 1px dotted black;
  padding: 10px;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
